<template>
  <div class="index-header">
    <div class="ih-body">
      <div class="ih-text">中国高校知识产权运营平台 欢迎您！</div>
      <div class="ih-ope">
        <div
          v-if="this.$route.path!=='/home/index'"
          @click="goIndex"
          class="iho-text" style="color: rgba(0, 64, 153, 1);margin-right: 10px">返回首页
        </div>
        <div class="iho-text"
             v-if="!token || !userId" @click="goLogin()"
        >
          企业登录
        </div>
        <div v-if="!token || !userId" class="iho-text"
             style="color:rgba(51, 51, 51, 1);margin-right: 5px;margin-left: 5px ">
          |
        </div>
        <div
          @click="goRegister()" v-if="!token||!userId "
          class="iho-text">
          注册
        </div>
        <div class="iho-text" style="color: rgba(51, 51, 51, 1)" v-if="token && userId">
          欢迎您！{{ userName }}
        </div>

        <div class="iho-text" style="color: rgba(24, 144, 255, 1);margin-left: 5px"
             v-if="enterpriseState === 'CERTIFIED'">
          已认证
        </div>

        <div class="iho-text" style="color: rgba(255, 82, 0, 1);margin-left: 5px"
             v-if="enterpriseState === 'EXAMINING'">
          待审核
        </div>

        <div
          @click="goRenZheng"
          class="iho-text" style="color: rgba(255, 30, 42, 1);margin-left: 5px"
          v-if="enterpriseState === 'UNCERTIFIED'">
          去认证
        </div>

        <div v-if="token && userId" class="iho-text"
             style="color:rgba(51, 51, 51, 1);margin-right: 5px;margin-left: 5px ">
          |
        </div>
        <div
          @click="logOut"
          v-if="token && userId" class="iho-text"
          style="color:rgba(51, 51, 51, 1)">
          退出
        </div>
        <div class="iho-text" style="margin-left: 25px;color: rgba(51, 51, 51, 1)" @click="goEnterprise">
          企业中心
        </div>
        <div class="iho-text" style="color:rgba(51, 51, 51, 1);margin-right: 5px;margin-left: 5px ">
          |
        </div>
        <div class="iho-text" style="color: rgba(51, 51, 51, 1)" @click="goConsult">
          联系客服
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import axios from "axios";
import baseUrl from "../../plugins/http/baseUrl";
import { hostname } from "../../config";
import { checkLogin } from "../../plugins/api/loginApi";
import { getLocalToken } from "../../plugins/tools/storage";

export default {
  name: "indexHeader",
  data() {
    return {
      siteUrl: "",
      siteKeywords: "",
      siteDescription: ""
    };
  },
  computed: {
    ...mapGetters({
      token: "userStore/token",
      userName: "userStore/userName",
      userId: "userStore/userId",
      enterpriseState: "userStore/enterpriseState"
    })
  },
  mounted() {
    this.$nextTick(() => {
      this.dominCookie = getLocalToken();
      let cookie_token = this.$cookies.get('token')
      if(cookie_token != this.token){
        this.logOut()
      }
    });

  },
  methods: {
    ...mapMutations({
      setUserToken: "userStore/setUserToken",
      setUserName: "userStore/setUserName",
      setUserAccountType: "userStore/setUserAccountType",
      setUserMbrMemberId: "userStore/setUserMbrMemberId",
      setUserId: "userStore/setUserId",
      setUserEnterpriseState: "userStore/setUserEnterpriseState"
    }),
    goRenZheng() {
      this.$router.push({
        name: "enterprise-EnterpriseCertification"
      });
    },
    goConsult() {
      openZoosUrl("chatwin");
    },
    logOut() {
      this.setUserToken("");
      this.setUserName("");
      this.setUserAccountType(-1);
      this.setUserMbrMemberId("");
      this.setUserId("");
      this.setUserEnterpriseState("");
      this.$cookies.set("token", "", null, null, "." + hostname);
    },
    goLogin() {
      this.$router.push({
        path: `/login`,
        params: {
          type: 1
        }
      });
    },
    goEnterprise() {
      if (!checkLogin(this.$router)) {
        this.logOut();
        return;
      }
      this.$router.push({
        path: "/enterprise/enterprise-management-center"
      });

    },
    goRegister() {
      this.$router.push({
        name: `login`,
        params: {
          type: 4
        }
      });
    },
    goIndex() {
      this.$router.push({
        path: "/"
      });
    }
  }

};
</script>

<style>

.index-header {
  display: flex;
  height: 40px;
  flex-direction: row;
  justify-content: center;
  background-color: #F7F8F9;
}

.ih-body {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  width: 1200px;
}

.ih-text {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.ih-ope {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.iho-text {
  font-size: 14px;
  font-weight: 400;
  color: #1890FF;
  cursor: pointer;
  flex-shrink: 0;
}
</style>
